<template>
  <div class="order-page">
    <v-header/>

    <div class="order-card">
      <div class="order-title">订单信息</div>
      <div class="goods-prop">
        <div class="goods-prop-label">订单编号</div>
        <div class="goods-prop-value">{{ order.orderNo }}</div>
      </div>
      <div class="goods-prop">
        <div class="goods-prop-label">订单状态</div>
        <div class="goods-prop-value">{{ (orderStatusOptions.find(o => o.value === order.status) || {}).label }}</div>
      </div>
      <div class="goods-prop" v-if="order.expressNo">
        <div class="goods-prop-label">快递单号</div>
        <div class="goods-prop-value">{{ order.expressNo }}</div>
      </div>

      <template v-if="order.status !== orderStatusEnum.CREATED">
        <div class="goods-prop">
          <div class="goods-prop-label">订单总额</div>
          <div class="goods-prop-value red">¥{{ order.totalPrice }}</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">实际付款</div>
          <div class="goods-prop-value red">¥{{ order.actualPrice }}</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">严值付款</div>
          <div class="goods-prop-value red">{{ order.yanzhi }}严值(¥{{ moneyFilter(order.yanzhi / 10) }})</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">实际付款</div>
          <div class="goods-prop-value red">¥{{ order.money }}</div>
        </div>
      </template>
    </div>

    <div class="order-card">
      <div class="order-title">收货信息</div>
      <div class="address">
        <div>
          <div class="address-name">{{ order.contact }} {{ order.mobile }}</div>
          <div class="address-info"> {{ order.province }}{{ order.city }}{{
              order.district
            }}{{ order.street }} {{ order.address }}
          </div>
        </div>
        <div></div>
      </div>
    </div>


    <div class="order-card">
      <div class="order-title">商品信息</div>
      <div>
        <router-link :to="`/shop/goods/${order.goodsId}`" class="goods-info">
          <img class="goods-cover" :src="order.goodsCover" alt="">
          <div>
            <div class="goods-title">{{ order.goodsTitle }}</div>
            <div class="goods-desc">{{ order.goodsDesc }}</div>
          </div>
        </router-link>
        <div class="goods-prop">
          <div class="goods-prop-label">商品单价</div>
          <div class="goods-prop-value red">¥{{ order.unitPrice }}</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">购买数量</div>
          <div class="goods-prop-value">{{ order.quantity }}</div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">小计</div>
          <div class="goods-prop-value red">¥{{ order.totalPrice }}</div>
        </div>
      </div>
    </div>


    <div class="order-card" v-if="order.goodsCouponRecordId">
      <div class="order-title">
        <div>优惠券</div>
      </div>
      <div class="coupon-cell">
        <div>
          {{ order.couponRecord.name }}
          <template v-if="order.couponRecord.type === 1">直减{{ order.couponRecord.value }}元</template>
          <template v-else-if="order.couponRecord.type === 2">{{ order.couponRecord.value }}折</template>
        </div>
        <div></div>
      </div>
    </div>


    <template v-if="order.status === orderStatusEnum.CREATED">
      <div class="order-card">
        <div class="order-title">支付方式</div>
        <div class="pay-btns">
          <div :class="`pay-btn wx ${order.payType===payTypeEnum.wx?'active':''}`"
               @click="order.payType=payTypeEnum.wx">
            <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-green.svg" alt=""
                 v-if="order.payType === payTypeEnum.wx">
            <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-gray.svg" alt="" v-else>
            微信支付
          </div>
          <div :class="`pay-btn alipay ${order.payType===payTypeEnum.alipay?'active':''}`"
               @click="order.payType=payTypeEnum.alipay">
            <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-blue.svg" alt=""
                 v-if="order.payType === payTypeEnum.alipay">
            <img src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-gray.svg" alt="" v-else>
            支付宝支付
          </div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">
            <div>使用严值</div>
            <div style="color: #999;font-size:12px">使用严值将不支持退款</div>
          </div>
          <div class="goods-prop-value">
            <van-switch v-model="order.useYanzhi" size="24px" active-color="#151d36"/>
          </div>
        </div>
        <div class="goods-prop">
          <div class="goods-prop-label">剩余严值</div>
          <div class="goods-prop-value red">{{ yanzhiFilter(wallet.totalRemainValue) }}严值
            (¥{{ moneyFilter(wallet.totalRemainValue / 10) }})
          </div>
        </div>
      </div>

      <div class="pay-line">
        <div class="red">¥{{ finalPrice }}</div>
        <div class="buy-btn" @click="startPay">去支付</div>
      </div>
    </template>
  </div>
</template>


------------------ script ------------------
<script>
import {goodsCouponTypeEnum, orderStatusEnum, orderStatusOptions, payTypeEnum} from "../../config/enums";
import {orderV2Api} from "../../api/v2/orderV2Api";
import {mapGetters} from "vuex";
import {getPaySubType, processPay, sleep} from "../../config/util";
import {Toast} from "vant";
import wxValidate from "../../config/wx.validate";

export default {
  mixins: [wxValidate],
  async mounted() {
    await this.loadOrder();
    await this.wxSignature();
    await sleep(100)
    if (this.$route.query.openPay === 'true') {
      await this.startPay();
    }
  },


  computed: {

    // 最终价格
    finalPrice() {
      let finalPrice = this.order.totalPrice;
      const coupon = this.order.couponRecord

      if (coupon) {
        if (coupon.type === 1) {
          finalPrice = finalPrice - coupon.value;
        } else if (coupon.type === 2) {
          finalPrice = this.moneyFilter(finalPrice * coupon.value / 10);
        }
      }

      if (this.order.useYanzhi) {
        finalPrice -= this.wallet.totalRemainValue / 10;
        finalPrice = finalPrice > 0 ? finalPrice : 0;
      }

      return finalPrice;
    },

    ...mapGetters(['wallet'])
  },


  data() {
    return {
      payTypeEnum,
      goodsCouponTypeEnum,
      orderStatusEnum,
      orderStatusOptions,
      visibles: {
        wxPay: false,
        alipay: false
      },
      pay: {
        consumeRecordId: ''
      },
      order: {
        id: '',
        orderNo: '',
        province: '',
        city: '',
        district: '',
        street: '',
        contact: '',
        mobile: '',
        address: '',
        goodsId: '',
        goodsCover: '',
        goodsType: '',
        goodsTitle: '',
        goodsDesc: '',
        unitPrice: '',
        quantity: '',
        totalPrice: '',
        goodsCouponRecordId: '',
        payType: '',
        paySubType: '',
        useYanzhi: false,
        actualPrice: '',
        status: '',
        expressNo: '',
        couponRecord: {
          id: '',
          type: '',
          value: '',
          name: '',
        }
      }
    }
  },


  methods: {
    async loadOrder() {
      this.order = await orderV2Api.findByOrderNo(this.$route.params.orderNo)
    },


    // 发起支付
    async startPay() {
      if (orderStatusEnum.CREATED !== this.order.status) {
        return;
      }
      this.order.paySubType = getPaySubType(this.order.payType);
      const res = await orderV2Api.startPay(this.order);
      if (res.isAllPayedByYanzhi) {
        Toast.success('支付成功')
        return this.loadOrder();
      }
      await processPay(this.order.payType, this.order.paySubType, res.payForm);
    },
  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./order";
</style>
